<template>
  <div
    class="search pa-4"
    :class="{ 'column-gap': !hideDocumentTabs }"
  >
    <search-toolbar
      class="toolbar"
      :use-responsive-search-field="useResponsiveSearchField"
      :search="search"
    />
    <search-documents
      v-if="!hideDocumentTabs"
      class="documents"
      :search="search"
    />
    <div class="list d-flex flex-column overflow-hidden">
      <search-list
        :search="search"
        @row-action="$emit('row-action', $event)"
        :scope-modes="scopeModes"
      />
      <v-pagination
        v-if="search.pageCount > 1"
        color="primary"
        :length="search.pageCount"
        total-visible=9
        v-model="page"
      />
    </div>
    <document-preview-popup
      v-model="search.showPreviewPopup"
      :document="search.previewDocument"
    />
  </div>
</template>

<script>
import Search, { GlobalSearch } from '../controllers'

export default {
  name: 'search',
  components: {
    DocumentPreviewPopup: () => import('@/pages/search/components/document-preview-popup'),
    SearchDocuments: () => import('./search-documents'),
    SearchList: () => import('./search-list'),
    SearchToolbar: () => import('./search-toolbar')
  },
  activated () {
    if (!this.lazy) {
      this.search.refreshResults()
      this.registerToGlobalSearch()
    }
  },
  beforeDestroy () {
    if (!this.lazy) {
      this.unregisterOfGlobalSearch()
    }
    this.search.clearItems()
    this.search = undefined
  },
  computed: {
    page: {
      get () {
        return this.search.page + 1
      },
      set (page) {
        this.search.setPage(page).execute()
      }
    },
    scopeModes () {
      return []
    }
  },
  data () {
    return {
      search: new Search(Search.actions.GRID)
        .setLinkTarget(this.linkTarget)
        .disableSearchOnType()
        .setScope(this.scope)
    }
  },
  deactivated () {
    if (!this.lazy) {
      this.unregisterOfGlobalSearch()
    }
  },
  mounted () {
    if (!this.lazy) {
      this.registerToGlobalSearch()
    }
    if (this.documentTypes) {
      this.search.searchedDocumentTypes.include(this.documentTypes)
    } else {
      this.search.searchedDocumentTypes.exclude([
        'aging-buckets',
        'allocation-announcement-invoices',
        'announcement-origins',
        'announcement-statuses',
        'ar24-message-statuses',
        'ar24-messages',
        'calendar-based-reminder-protocols',
        'calendar-based-reminder-statuses',
        'collaboration-task-types',
        'collection-segments',
        'contact-groups',
        'credit-hold-reasons',
        'credit-safes',
        'credit-status-groupamas',
        'credit-statuses',
        'cultures',
        'currencies',
        'dispute-reason-categories',
        'dispute-reasons',
        'document-types',
        'dunning-reminder-statuses',
        'dunning-runs',
        'escalation-protocol-levels',
        'fields',
        'group-contributors',
        'groupama-decision-codes',
        'groups',
        'import-data-sources',
        'invoice-statuses',
        'maileva-letters',
        'maileva-letter-statuses',
        'main-presets',
        'message-priorities',
        'payments',
        'portal-payment-statuses',
        'portal-statuses',
        'promise-installments',
        'promise-statuses',
        'provider-types',
        'relative-currencies',
        'reminder-types',
        'risk-categories',
        'roles',
        'run-statuses',
        'run-types',
        'status-disputes',
        'summaries',
        'templates',
        'transaction-types',
        'work-item-priorities',
        'work-item-types',
        'workflows'
      ])
    }

    if (this.scopeModes[0] !== 'global') {
      this.search.searchedDocumentTypes.exclude(['user-accounts'])
    }

    // all this permissions validation need to be in backend is_granted
    if (!this.$store.getters.isMasterAdmin()) {
      if (!this.$store.getters.currentUserHasPermission('Accounts')) {
        this.search.searchedDocumentTypes.exclude(['accounts'])
      }

      if (!this.$store.getters.currentUserHasPermission('AccountContacts')) {
        this.search.searchedDocumentTypes.exclude(['account-contacts'])
      }

      if (!this.$store.getters.currentUserHasPermission('UserSettings')) {
        this.search.searchedDocumentTypes.exclude(['users'])
      }

      if (!this.$store.getters.currentUserHasPermission('UserSettings')) {
        this.search.searchedDocumentTypes.exclude(['users'])
      }

      if (!this.$store.getters.currentUserHasPermission('WorkQueueSettings')) {
        this.search.searchedDocumentTypes.exclude(['work-queues'])
      }

      if (!this.$store.getters.currentUserHasAnyPermission(['CollectionStrategySettings', 'AccountCanDebugProtocol'])) {
        this.search.searchedDocumentTypes.exclude(['escalation-protocols'])
      }

      if (!this.$store.getters.currentUserHasPermission('TaskEngines')) {
        this.search.searchedDocumentTypes.exclude(['processes'])
      }

      if (!this.$store.getters.currentUserHasPermission('AgingBucketSettings')) {
        this.search.searchedDocumentTypes.exclude(['aging-scenarios'])
      }

      if (!this.$store.getters.currentUserHasPermission('CompanyStructureSettings')) {
        this.search.searchedDocumentTypes.exclude(['business-divisions'])
      }

      if (!this.$store.getters.currentUserHasPermission('GeneralSettings')) {
        this.search.searchedDocumentTypes.exclude(['providers', 'columns'])
      }

      if (!this.lazy) {
        this.search.execute()
      }
    }
  },
  methods: {
    registerToGlobalSearch () {
      if (this.scopeModes.length) {
        GlobalSearch.register(this.search, this.scopeModes)
      } else {
        GlobalSearch.forceActiveSearch(this.search)
        this.search.watchMainPreset()
      }
    },
    unregisterOfGlobalSearch () {
      if (this.scopeModes.length) {
        GlobalSearch.unregister(this.search)
      } else {
        this.search.unwatchMainPreset()
        GlobalSearch.removeForcedActiveSearch()
      }
    }
  },
  watch: {
    hideActions: {
      handler (v) {
        this.search.disableItemSelection(v)
      },
      immediate: true
    }
  },
  props: {
    documentTypes: Array,
    hideActions: Boolean,
    hideDocumentTabs: Boolean,
    lazy: Boolean,
    linkTarget: Object,
    scope: Search.typeOfScope,
    useResponsiveSearchField: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.search
  flex-grow 1
  display grid
  grid-template 'documents toolbar' 'documents list' 1fr / auto 1fr
  row-gap 3px

.column-gap
  column-gap 16px

.toolbar
  grid-area toolbar

.documents
  grid-area documents
  flex-grow 0

.list
  grid-area list
</style>
